const noop = () => null;

export const isClient = typeof window === "object";

export const isClientDebug =
  isClient &&
  window.location &&
  window.location.href &&
  window.location.href.includes &&
  window.location.href.includes("debug=true");

const dev = {
  console:
    process.env.NODE_ENV !== "production" || isClientDebug
      ? console
      : { log: noop, warn: noop, error: noop },
};

export default dev;
