exports.components = {
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-articles-jsx": () => import("./../../../src/templates/Articles.jsx" /* webpackChunkName: "component---src-templates-articles-jsx" */),
  "component---src-templates-artist-jsx": () => import("./../../../src/templates/Artist.jsx" /* webpackChunkName: "component---src-templates-artist-jsx" */),
  "component---src-templates-creation-jsx": () => import("./../../../src/templates/Creation.jsx" /* webpackChunkName: "component---src-templates-creation-jsx" */),
  "component---src-templates-directors-jsx": () => import("./../../../src/templates/Directors.jsx" /* webpackChunkName: "component---src-templates-directors-jsx" */),
  "component---src-templates-films-jsx": () => import("./../../../src/templates/Films.jsx" /* webpackChunkName: "component---src-templates-films-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-netarts-jsx": () => import("./../../../src/templates/Netarts.jsx" /* webpackChunkName: "component---src-templates-netarts-jsx" */)
}

